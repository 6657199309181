import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { navigate } from "gatsby";
import axios from "axios";

import {
    hideHeader,
    hideBottomNav,
    hideSideDrawer,
    logOut,
} from "../../../state/actions";

import { Container, Row, Col } from "reactstrap";
import Heading from "../../atoms/Heading/Heading";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";
import NotificationsListItem from "../../molecules/NotificationsListItem/NotificationsListItem";
import Loader from "../../atoms/Loader/Loader";
import isBrowser from "../../../utils/isBrowser";

import "./notificationsList.scss";

const NotificationsList = () => {
    const dispatch = useDispatch();
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(hideBottomNav());
        dispatch(hideSideDrawer());
        dispatch(hideHeader());

        loadData();
    }, []);

    const loadData = () => {
        axios
            .get(
                `${process.env.STRAPI_API_URL}/notifications?_sort=created_at:DESC`
            )
            .then((res) => {
                setNotifications(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                const { status } = err.response;
                setIsLoading(false);

                if (status === 401 || status === 403 || status === 405) {
                    dispatch(logOut());
                    navigate("/app/logowanie");
                }
            });
    };

    return (
        <Container className="notifications-list">
            <Row>
                <Col>
                    <Button
                        className="btn--circle btn--back"
                        onClick={() =>
                            isBrowser() ? window.history.back() : ""
                        }
                        aria-label="Cofnij do poprzedniego widoku"
                    >
                        <Icon className="icon-chevron-left-min" />
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Heading as="h2">Powiadomienia</Heading>

                    {isLoading && <Loader />}

                    {!isLoading && !notifications.length && (
                        <p className="notifications-list--empty-msg">
                            Brak powiadomień
                        </p>
                    )}

                    {!isLoading &&
                        notifications.length > 0 &&
                        notifications.map((n) => (
                            <NotificationsListItem key={n.id} {...n} />
                        ))}
                </Col>
            </Row>
        </Container>
    );
};

export default NotificationsList;
